<template>
  <div class="container justify-content-start align-items-start">
      <div class="col-12">
        <h1 class="colorfull">Impressum</h1>
        <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
        <p>Patrick Linner<br/>
          Hanreiweg 4<br/>
          86153 Augsburg</p>
        <h3>Kontakt</h3>
        <p>Telefon: 082122939468<br/>
          E-Mail: media@patrick-linner.de</p>
      </div>
    </div>

</template>

<script>
export default {
  name: 'impressum',
};
</script>

<style scoped>
* {
  text-align: left;
}
.container {
  margin-top: 5vh;
}
</style>
